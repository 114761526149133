import React from "react";
import { Link } from "gatsby"
import Icon from '@mdi/react'
import { mdiArrowLeft } from '@mdi/js';

const h1Classes = `text-2xl m-2`
const h2Classes = `text-xl font-bold mt-3`
const h3Classes = `text-xl font-bold mt-3`
const h4Classes = `font-bold mt-2`
const ulClasses = `list-disc ml-4`

export default function PrivacyPage() {
  return (
    <main>
      <div className="container mx-auto px-10 py-20 text-primary font-gothic antialiased text-base font-normal leading-tight">
        <Link
          to="/"
          className="inline-flex items-center py-1 rounded text-inactive hover:text-primary focus:text-primary duration-500 text-lg font-medium mt-4 mb-4">
          <Icon
            className="hidden md:inline w-4 h-4 ml-1 mr-1"
            path={mdiArrowLeft}
            title="Back"
          />
          Back
        </Link>
        <section id="german-privacy" className="">
          <h1 className={h1Classes}>Datenschutz&shy;erkl&auml;rung</h1>
          <a href="#english-privacy" ml-5 mr-5>(english version below)</a>
          <h2 className={h2Classes}>1. Datenschutz auf einen Blick</h2>
          <h3 className={h3Classes}>Allgemeine Hinweise</h3>
          <span>Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.</span>

          <h3 className={h3Classes}>Datenerfassung auf dieser Website</h3>
          <h4 className={h4Classes}>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h4>
          <span>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Impressum dieser Website entnehmen.</span>

          <h4 className={h4Classes}>Wie erfassen wir Ihre Daten?</h4>
          <span>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular eingeben. </span>
          <span>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</span>

          <h4 className={h4Classes}>Wof&uuml;r nutzen wir Ihre Daten?</h4>
          <span>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.</span>

          <h4 className={h4Classes}>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>
          <span>Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu. </span>
          <span>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</span>

          <h3 className={h3Classes}>Analyse-Tools und Tools von Dritt&shy;anbietern</h3>
          <span>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen. </span>
          <span>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerkl&auml;rung.</span>

          <h2 className={h2Classes}>2. Hosting und Content Delivery Networks (CDN)</h2>
          <h3 className={h3Classes}>Externes Hosting</h3>
          <span>Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. </span>
          <span>Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die &uuml;ber eine Website generiert werden, handeln. </span>
          {/* <span>Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). </span> */}
          <span>Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen. </span>

          <h2 className={h2Classes}>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
          <h3 className={h3Classes}>Datenschutz</h3>
          <span>Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.</span>
          <span>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.</span>
          <span>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</span>

          <h3 className={h3Classes}>Hinweis zur verantwortlichen Stelle</h3>
          <span>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist: </span>
          <p>Stefan Junk<br />
            Michaelkirchplatz 24<br />
            10179 Berlin<br />
            Germany<br />
          </p>
          <p>E-Mail: stefan[at]stefanjunk.com</p>
          <span>Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.</span>

          <h3 className={h3Classes}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
          <span>Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.</span>

          <h3 className={h3Classes}>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)</h3>
          <span>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). </span>
          {/* <span>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</span> */}

          <h3 className={h3Classes}>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</h3> <span>Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</span>
          <h3 className={h3Classes}>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>
          <span>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</span>

          <h3 className={h3Classes}>SSL- bzw. TLS-Verschl&uuml;sselung</h3>
          <span>Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</span>
          <span>Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</span>

          <h3 className={h3Classes}>Auskunft, L&ouml;schung und Berichtigung</h3>
          <span>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</span>

          <h3 className={h3Classes}>Recht auf Einschr&auml;nkung der Verarbeitung</h3>
          <span>Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:</span>
          <ul className={ulClasses}>
            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.</li>
            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
          </ul>
          <span>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.</span>

          <h3 className={h3Classes}>Widerspruch gegen Werbe-E-Mails</h3>
          <span>Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</span>

          <h2 className={h2Classes}>4. Datenerfassung auf dieser Website</h2>

          <h3 className={h3Classes}>Cookies</h3>
          <span>Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;. Cookies sind kleine Textdateien und richten auf Ihrem Endger&auml;t keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst l&ouml;schen&nbsp;oder eine automatische L&ouml;schung durch Ihren Webbrowser erfolgt. </span>
          <span>Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf Ihrem Endger&auml;t gespeichert werden, wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese erm&ouml;glichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).</span>
          <span>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren w&uuml;rden (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten&nbsp;oder Werbung anzuzeigen. </span>
          <span>Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen (funktionale Cookies, z. B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung der Website (z.B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies ausschlie&szlig;lich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar. </span>
          <span>Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en sowie das automatische L&ouml;schen der Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.</span> <span>Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hier&uuml;ber im Rahmen dieser Datenschutzerkl&auml;rung gesondert informieren und ggf. eine Einwilligung abfragen.</span>

          <h3 className={h3Classes}>Server-Log-Dateien</h3>
          <span>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:</span>
          <ul className={ulClasses}>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </ul>
          <span>Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. </span>
          <span>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst werden.</span>

          <h3 className={h3Classes}>Kontaktformular</h3>
          <span>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</span> <span>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</span> <span>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.</span>

          <h3 className={h3Classes}>Anfrage per E-Mail, Telefon oder Telefax</h3>
          <span>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</span> <span>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</span> <span>Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.</span>

          <h2 className={h2Classes}>5. Soziale Medien</h2>

          <span>Auf dieser Website werden keine Plugins zu sozialen Netzwerken integriert.</span>

          {/* <h3 className={h3Classes}>Facebook Plugins (Like &amp; Share-Button)</h3>
          <span>Auf dieser Website sind Plugins des sozialen Netzwerks Facebook integriert. Anbieter dieses Dienstes ist die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere Drittl&auml;nder &uuml;bertragen.</span>
          <span>Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem &bdquo;Like-Button&ldquo; (&bdquo;Gef&auml;llt mir&ldquo;) auf dieser Website. Eine &Uuml;bersicht &uuml;ber die Facebook Plugins finden Sie hier: <a href="https://developers.facebook.com/docs/plugins/?locale=de_DE" target="_blank" rel="noopener noreferrer">https://developers.facebook.com/docs/plugins/?locale=de_DE</a>.</span>
          <span>Wenn Sie diese Website besuchen, wird &uuml;ber das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erh&auml;lt dadurch die Information, dass Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den Facebook &bdquo;Like-Button&ldquo; anklicken w&auml;hrend Sie in Ihrem Facebook-Account eingeloggt sind, k&ouml;nnen Sie die Inhalte dieser Website auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Facebook unter: <a href="https://de-de.facebook.com/privacy/explanation" target="_blank" rel="noopener noreferrer">https://de-de.facebook.com/privacy/explanation</a>.</span> <span>Wenn Sie nicht w&uuml;nschen, dass Facebook den Besuch dieser Website Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.</span> <span>Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</span>

          <h3 className={h3Classes}>Twitter Plugin</h3>
          <span>Auf dieser Website sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden angeboten durch die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Durch das Benutzen von Twitter und der Funktion &bdquo;Re-Tweet&ldquo; werden die von Ihnen besuchten Websites mit Ihrem Twitter-Account verkn&uuml;pft und anderen Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter &uuml;bertragen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Twitter unter: <a href="https://twitter.com/de/privacy" target="_blank" rel="noopener noreferrer">https://twitter.com/de/privacy</a>.</span> <span>Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</span> <span>Ihre Datenschutzeinstellungen bei Twitter k&ouml;nnen Sie in den Konto-Einstellungen unter <a href="https://twitter.com/account/settings" target="_blank" rel="noopener noreferrer">https://twitter.com/account/settings</a> &auml;ndern.</span>

          <h3 className={h3Classes}>Instagram Plugin</h3>
          <span>Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025, USA integriert.</span>
          <span>Wenn Sie in Ihrem Instagram-Account eingeloggt sind, k&ouml;nnen Sie durch Anklicken des Instagram-Buttons die Inhalte dieser Website mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch Instagram erhalten.</span>
          <span>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</span>
          <span>Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Instagram: <a href="https://instagram.com/about/legal/privacy/" target="_blank" rel="noopener noreferrer">https://instagram.com/about/legal/privacy/</a>.</span>

          <h3 className={h3Classes}>Tumblr Plugin</h3>
          <span>Diese Website nutzt Schaltfl&auml;chen des Dienstes Tumblr. Anbieter ist die Tumblr, Inc., 35 East 21st St, 10th Floor, New York, NY 10010, USA.</span>
          <span>Diese Schaltfl&auml;chen erm&ouml;glichen es Ihnen, einen Beitrag oder eine Seite bei Tumblr zu teilen oder dem Anbieter bei Tumblr zu folgen. Wenn Sie eine unserer Websites mit Tumblr-Button aufrufen, baut der Browser eine direkte Verbindung mit den Servern von Tumblr auf. Wir haben keinen Einfluss auf den Umfang der Daten, die Tumblr mit Hilfe dieses Plugins erhebt und &uuml;bermittelt. Nach aktuellem Stand werden die IP-Adresse des Nutzers sowie die URL der jeweiligen Website &uuml;bermittelt.</span>
          <span>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</span>
          <span>Weitere Informationen hierzu finden sich in der Datenschutzerkl&auml;rung von Tumblr unter: <a href="https://www.tumblr.com/privacy/de" target="_blank" rel="noopener noreferrer">https://www.tumblr.com/privacy/de</a>.</span>

          <h3 className={h3Classes}>Pinterest Plugin</h3>
          <span>Auf dieser Website verwenden wir Social Plugins des sozialen Netzwerkes Pinterest, das von der Pinterest Inc., 808 Brannan Street, San Francisco, CA 94103-490, USA (&bdquo;Pinterest&ldquo;) betrieben wird.</span>
          <span>Wenn Sie eine Seite aufrufen, die ein solches Plugin enth&auml;lt, stellt Ihr Browser eine direkte Verbindung zu den Servern von Pinterest her. Das Plugin &uuml;bermittelt dabei Protokolldaten an den Server von Pinterest in die USA. Diese Protokolldaten enthalten m&ouml;glicherweise Ihre IP-Adresse, die Adresse der besuchten Websites, die ebenfalls Pinterest-Funktionen enthalten, Art und Einstellungen des Browsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise von Pinterest sowie Cookies.</span>
          <span>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</span>
          <span>Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung und Nutzung der Daten durch Pinterest sowie Ihre diesbez&uuml;glichen Rechte und M&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re finden Sie in den Datenschutzhinweisen von Pinterest: <a href="https://policy.pinterest.com/de/privacy-policy" target="_blank" rel="noopener noreferrer">https://policy.pinterest.com/de/privacy-policy</a>.</span> */}

          <h2 className={h2Classes}>6. Analyse-Tools</h2>
          <h3 className={h3Classes}>Google Analytics</h3>
          <span>Wir nutzen die Analysedienste von &bdquo;Google Analytics&ldquo; auf dieser Website. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland. </span>
          <span>Im Rahmen der Analysen mit &bdquo;Google Analytics&ldquo; k&ouml;nnen u. a. Besucherzahlen und &ndash;verhalten (z.&nbsp;B. Anzahl der Seitenaufrufe, Dauer eines Webseitenbesuchs, Absprungraten), Besucherquellen (d. h., von welcher Seite der Besucher kommt), Besucherstandorte sowie technische Daten (Browser- und Betriebssystemversionen) analysiert werden. </span>
          <span>Die Analysen laufen vollst&auml;ndig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse stattfindet. </span>
          <span>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbr&auml;uchlicher automatisierter Aussp&auml;hung und vor SPAM zu sch&uuml;tzen. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar. </span>
          <span>Weitere Informationen zu Google Analytics entnehmen Sie den Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter folgenden Links: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a> und <a href="https://policies.google.com/terms?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/terms?hl=de</a>. </span>

          {/* <h3 className={h3Classes}>Matomo (ehemals Piwik)</h3>
          <span>Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo verwendet Technologien, die die seiten&uuml;bergreifende Wiedererkennung des Nutzers zur Analyse des Nutzerverhaltens erm&ouml;glichen (z.B. Cookies oder Device-Fingerprinting). Die durch Matomo erfassten Informationen &uuml;ber die Benutzung dieser Website werden auf unserem Server gespeichert. Die IP-Adresse wird vor der Speicherung anonymisiert.</span>
          <span>Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der anonymisierten Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</span>
          <span>Die durch Matomo erfassten Informationen &uuml;ber die Benutzung dieser Website werden nicht an Dritte weitergegeben.</span> */}

          {/* <h3 className={h3Classes}>IONOS Web Analytics</h3>
          <span>Diese Website nutzt die Analysedienste von IONOS-Webanalytics (im Folgenden: IONOS). Anbieter ist die 1&amp;1 IONOS SE, Elgendorfer Stra&szlig;e 57, D &ndash; 56410 Montabaur. Im Rahmen der Analysen mit IONOS k&ouml;nnen u. a. Besucherzahlen und &ndash;verhalten (z.&nbsp;B. Anzahl der Seitenaufrufe, Dauer eines Webseitenbesuchs, Absprungraten), Besucherquellen (d. h., von welcher Seite der Besucher kommt), Besucherstandorte sowie technische Daten (Browser- und Betriebssystemversionen) analysiert werden. Zu diesem Zweck speichert IONOS insbesondere folgende Daten:</span>
          <ul>
            <li>Referrer (zuvor besuchte Webseite)</li>
            <li>angeforderte Webseite oder Datei</li>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>verwendeter Ger&auml;tetyp</li>
            <li>Uhrzeit des Zugriffs</li>
            <li>IP-Adresse in anonymisierter Form (wird nur zur Feststellung des Orts des Zugriffs verwendet)</li>
          </ul>
          <span>Die Datenerfassung erfolgt laut IONOS vollst&auml;ndig anonymisiert, sodass sie nicht zu einzelnen Personen zur&uuml;ckverfolgt werden kann. Cookies werden von IONOS-Webanalytics nicht gespeichert.</span>
          <span>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der statistischen Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</span>
          <span>Weitere Informationen zur Datenerfassung und Verarbeitung durch IONOS-Webanalytics entnehmen Sie der Datenschutzerklärung von IONOS unter folgendem Link:</span> <span><a href="https://www.ionos.de/terms-gtc/index.php?id=6" target="_blank" rel="noopener noreferrer">https://www.ionos.de/terms-gtc/index.php?id=6</a></span> */}

          <h2 className={h2Classes}>7. Newsletter</h2>

          <span>Auf dieser Webseite wird kein Newsletter angeboten.</span>

          {/* <h3 className={h3Classes}>Newsletter&shy;daten</h3>
          <span>Wenn Sie den auf der Website angebotenen Newsletter beziehen m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschlie&szlig;lich f&uuml;r den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.</span>
          <span>Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa &uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter. Die Rechtm&auml;&szlig;igkeit der bereits erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</span> <span>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters aus der Newsletterverteilerliste gel&ouml;scht. Daten, die zu anderen Zwecken bei uns gespeichert wurden bleiben hiervon unber&uuml;hrt.</span>
          <span>Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um k&uuml;nftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur f&uuml;r diesen Zweck verwendet und nicht mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.</span>
          <h4 className={h4Classes}>Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse &uuml;berwiegen.</h4> */}

          <h2 className={h2Classes}>8. Plugins und Tools</h2>
          <h3 className={h3Classes}>Google Web Fonts</h3>
          <span>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</span>
          <span>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung von Google WebFonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der einheitlichen Darstellung des Schriftbildes auf seiner Website. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</span> <span>Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer genutzt.</span> <span>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> und in der Datenschutzerkl&auml;rung von Google: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</span>

          <h3 className={h3Classes}>Google reCAPTCHA</h3>
          <span>Wir nutzen &bdquo;Google reCAPTCHA&ldquo; (im Folgenden &bdquo;reCAPTCHA&ldquo;) auf dieser Website. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland. </span>
          <span>Mit reCAPTCHA soll &uuml;berpr&uuml;ft werden, ob die Dateneingabe auf dieser Website (z.&nbsp;B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.&nbsp;B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer get&auml;tigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet. </span>
          <span>Die reCAPTCHA-Analysen laufen vollst&auml;ndig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse stattfindet. </span>
          <span>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbr&auml;uchlicher automatisierter Aussp&auml;hung und vor SPAM zu sch&uuml;tzen. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar. </span>
          <span>Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter folgenden Links: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a> und <a href="https://policies.google.com/terms?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/terms?hl=de</a>. </span>

          <p className="mt-3">Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
        </section>

        <hr className="mt-6" />
        <section id="english-privacy" className="">
          <h1 className={h1Classes}>Privacy Policy</h1>
          <p>(Translated from german language by &ldquo;Google Translate&rdquo;. Translation errors may occur)</p>
          <h2 className={h2Classes}>1. Data protection at a glance</h2>
          <h3 className={h3Classes}>General Notes</h3>
          <span>The following notes provide a simple overview of what happens to your personal data when you visit this website. Personal data is all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our data protection declaration below this text.</span>

          <h3 className={h3Classes}>Data collection on this website</h3>
          <h4 className={h4Classes}>Who is responsible for data collection on this website?</h4>
          <span>The data processing on this website is carried out by the website operator. You can find his contact details in the imprint of this website.</span>

          <h4 className={h4Classes}>How do we collect your data?</h4>
          <span>On the one hand, your data is collected when you communicate it to us. This can be e.g. deal with data that you enter in a contact form. </span>
          <span>Other data is collected automatically or with your consent by our IT systems when you visit the website. This is primarily technical data (e.g. internet browser, operating system or time of the page view). This data is collected automatically as soon as you enter this website.</span>

          <h4 className={h4Classes}>What do we use your data for?</h4>
          <span>Part of the data is collected to ensure that the website is provided without errors. Other data can be used to analyze your user behavior.</span>

          <h4 className={h4Classes}>What are your rights regarding your data?</h4>
          <span>You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right to request that the processing of your personal data be restricted under certain circumstances. Furthermore, you have the right to lodge a complaint with the competent supervisory authority. </span>
          <span>You can contact us at any time at the address given in the imprint for this and other questions on the subject of data protection.</span>

          <h3 className={h3Classes}>Analytical and third-party tools</h3>
          <span>When you visit this website, your surfing behavior can be statistically evaluated. This is mainly done with so-called analysis programs. </span>
          <span>You can find detailed information about these analysis programs in the following data protection declaration.</span>

          <h2 className={h2Classes}>2. Hosting and Content Delivery Networks (CDN)</h2>
          <h3 className={h3Classes}>External hosting</h3>
          <span>This website is hosted by an external service provider (hoster). The personal data collected on this website is stored on the host's servers. </span>
          <span>This can be v. a. are IP addresses, contact requests, meta and communication data, contract data, contact data, names, website access and other data generated via a website. </span>
          {/* <span>The hoster is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 Para. 1 lit. b GDPR) and in the interest of a secure, fast and efficient Provision of our online offer by a professional provider (Art. 6 Para. 1 lit. f GDPR). </span> */}
          <span>Our hoster will only process your data to the extent necessary to fulfill his performance obligations and will follow our instructions in relation to this data. </span>

          <h2 className={h2Classes}>3. General information and mandatory information</h2>
          <h3 className={h3Classes}>Privacy Policy</h3>
          <span>The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the legal data protection regulations and this data protection declaration.</span>
          <span>If you use this website, various personal data will be collected. Personal data is data with which you can be personally identified. This data protection declaration explains what data we collect and what we use it for. It also explains how and for what purpose this happens.</span>
          <span>We would like to point out that data transmission on the Internet (e.g. when communicating via e- Mail) may have security gaps. A complete protection of the data against access by third parties is not possible.</span>

          <h3 className={h3Classes}>Note on the responsible body</h3>
          <span>The responsible body for data processing on this website is: </span>
          <p>Stefan Junk<br />
            Michaelkirchplatz 24<br />
            10179 Berlin<br />
            Germany<br />
          </p>
          <p>Email: stefan[at]stefanjunk.com</p>
          <span>Responsible body is the natural or legal person who alone or together with others is responsible for the purposes and means of processing personal data (e.g. names, e-mail addresses o. &Auml;.) decides.</span>

          <h3 className={h3Classes}>Revocation of your consent to data processing</h3>
          <span>Many data processing operations are only possible with your express consent. You can revoke consent that you have already given at any time. The legality of the data processing that took place up until the revocation remains unaffected by the revocation.</span>

          <h3 className={h3Classes}>Right to object to data collection in special cases and to direct advertising (Art. 21 GDPR)</h3>
          <span>IF THE DATA PROCESSING IS BASED ON ART. 6 ABS. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT AT ANY TIME TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS ON WHICH A PROCESSING IS BASED CAN BE FOUND IN THIS DATA PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR CONCERNED PERSONAL DATA UNLESS WE CAN PROVE COMPREHENSIVE PROTECTIVE GROUNDS FOR THE PROCESSING THAT OVERRIDE YOUR INTERESTS, RIGHTS AND FREEDOMS OR THE PROCESSING USE TO CLAIM, EXERCISE OR DEFEND LEGAL CLAIMS (OBJECTION ACCORDING TO ART. 21 (1) GDPR). </span>
          {/* <span>IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF YOUR PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING; THIS ALSO APPLIES TO PROFILING TO THE EXTENT RELATED TO SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION ACCORDING TO ART. 21 (2) GDPR).</span> */}

          <h3 className={h3Classes}>Right of appeal to the competent supervisory authority</h3> <span>In the event of violations of the GDPR, those affected have a right of appeal to a supervisory authority, in particular in the member state of their habitual residence, their place of work or the place of the alleged violation. The right to lodge a complaint is without prejudice to any other administrative or judicial remedy.</span>
          <h3 className={h3Classes}>Right to data portability</h3>
          <span>You have the right to have data that we process automatically on the basis of your consent or in fulfillment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another person responsible, this will only be done to the extent that it is technically feasible.</span>

          <h3 className={h3Classes}>SSL or TLS encryption</h3>
          <span>This site uses SSL or TLS encryption for security reasons and to protect the transmission of confidential content, such as orders or inquiries that you send to us as the site operator. You can recognize an encrypted connection by the fact that the address line of the browser changes from &ldquo;http://&rdquo; on &ldquo;https://&rdquo; changes and at the lock symbol in your browser line.</span>
          <span>If SSL or TLS encryption is activated, the data that you transmit to us cannot be read by third parties.</span>

          <h3 className={h3Classes}>Access, cancellation and rectification</h3>
          <span>Within the scope of the applicable legal provisions, you have the right to free information about your stored personal data, its origin and recipient and the purpose of the data processing and, if necessary, a right to correction or lengthening at any time. creation of this data. You can contact us at any time at the address given in the legal notice if you have further questions on the subject of personal data.</span>

          <h3 className={h3Classes}>Right to restriction of processing</h3>
          <span>You have the right to request that the processing of your personal data be restricted. You can contact us at any time at the address given in the imprint. The right to restriction of processing exists in the following cases:</span>
          <ul className={ulClasses}>
            <li className="">If you dispute the accuracy of your personal data stored by us, we usually need time to check this. For the duration of the examination, you have the right to request that the processing of your personal data be restricted.</li>
            <li>If the processing of your personal data was/is unlawful, you can request the restriction of data processing instead of deletion.</li>
            <li>If we no longer need your personal data, but you need it to exercise, defend or assert legal claims, you have the right to have the processing of your data restricted instead of deleted to request personal data.</li>
            <li>If you have lodged an objection in accordance with Art. 21 (1) GDPR, your interests and ours must be weighed up. As long as it has not yet been determined whose interests prevail, you have the right to request that the processing of your personal data be restricted.</li>
          </ul>
          <span>If you have restricted the processing of your personal data, this data &ndash; apart from their storage - processed only with your consent or to assert, exercise or defend legal claims or to protect the rights of another natural or legal person or for reasons of important public interest of the European Union or a member state become.</span>

          <h3 className={h3Classes}>Objection to promotional emails</h3>
          <span>The use of contact data published as part of the imprint obligation for the transmission of advertising and information material that has not been expressly requested is hereby contradicted. The site operators expressly reserve the right to take legal action in the event of unsolicited advertising being sent, such as spam e-mails.</span>

          <h2 className={h2Classes}>4. Data collection on this website</h2>
          <h3 className={h3Classes}>Cookies</h3>
          <span>Our website uses so-called &ldquo;cookies&rdquo;. Cookies are small text files and do not damage your end device. They are stored on your end device either temporarily for the duration of a session (session cookies) or permanently (permanent cookies). Session cookies are automatically deleted after your visit. Permanent cookies remain stored on your end device until you delete them yourself or until your web browser automatically deletes them. </span>
          <span>In some cases, cookies from third-party companies can also be stored on your device when you enter our site (third-party cookies). These enable us or you to use certain services of the third-party company (e.g. cookies for processing payment services).</span>
          <span>Cookies have different functions. Numerous cookies are technically necessary because certain website functions would not work without them (e.g. the shopping cart function or the display of videos). Other cookies are used to evaluate user behavior or to display advertising. </span>
          <span>Cookies that are used to carry out the electronic communication process (necessary cookies) or to provide certain functions you want (functional cookies, e.g. for the shopping cart function) or to optimize the Website (e.g. cookies for measuring the web audience) are required on the basis of Art. 6 (1) lit. f GDPR, unless another legal basis is given. The website operator has a legitimate interest in the storage of cookies for the technically error-free and optimized provision of its services. If consent to the storage of cookies was requested, the relevant cookies are stored exclusively on the basis of this consent (Article 6 (1) (a) GDPR); the consent can be revoked at any time. </span>
          <span>You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, accept cookies for certain cases or generally exclude them and activate the automatic deletion of cookies when the browser is closed. If cookies are deactivated, the functionality of this website may be restricted.</span> <span>Insofar as cookies are used by third-party companies or for analysis purposes, we will inform you about this in the context of this data protection declaration inform separately and, if necessary, request consent.</span>

          <h3 className={h3Classes}>Server log files</h3>
          <span>The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are:</span>
          <ul className={ulClasses}>
            <li>Browser type and browser version</li>
            <li>Operating system used</li>
            <li>Referrer URL</li>
            <li>Hostname of the accessing computer</li>
            <li>Time of server request</li>
            <li>IP address</li>
          </ul>
          <span>This data is not merged with other data sources. </span>
          <span>This data is collected on the basis of Art. 6 Para. 1 lit. f GDPR. The website operator has a legitimate interest in the technically error-free presentation and optimization of his website - to do this, the server log files must be recorded.</span>

          <h3 className={h3Classes}>Contact form</h3>
          <span>If you send us inquiries via the contact form, your details from the inquiry form, including the contact details you provided there, will be stored by us for the purpose of processing the inquiry and in the event of follow-up questions. We do not pass on this data without your consent.</span> <span>The processing of this data takes place on the basis of Art related or necessary to carry out pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of inquiries addressed to us (Art. 6 Para. 1 lit. f GDPR) or on your consent (Art. 6 Para. 1 lit. a DSGVO) if this was requested.</span> <span>The data you enter in the contact form will remain with us until you ask us to delete it, revoke your consent to storage or the purpose for the Data is not stored (e.g. after your request has been processed). Mandatory Legal Provisions - in particular retention periods - remain untouched.</span>

          <h3 className={h3Classes}>Request by email, telephone or fax</h3>
          <span>If you contact us by e-mail, telephone or fax, your inquiry including all resulting personal data (name, inquiry) will be stored and processed by us for the purpose of processing your request. We do not pass on this data without your consent.</span> <span>The processing of this data takes place on the basis of Art related or necessary to carry out pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of inquiries addressed to us (Art. 6 Para. 1 lit. f GDPR) or on your consent (Art. 6 Para. 1 lit. a DSGVO) if this was requested.</span> <span>The data you send to us via contact requests will remain with us until you ask us to delete them, revoke your consent to storage or the purpose for data storage does not apply (e.g. after your request has been processed). Mandatory Legal Provisions - in particular statutory retention periods - remain untouched.</span>

          <h2 className={h2Classes}>5. Social Media</h2>

          <span>No plugins for social networks are integrated on this website.</span>

          <h2 className={h2Classes}>6. Analysis Tools</h2>
          <h3 className={h3Classes}>Google Analytics</h3>
          <span>We use the analysis services of &ldquo;Google Analytics&rdquo; on this site. The provider is Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4, Ireland. </span>
          <span>As part of the analysis with &ldquo;Google Analytics&rdquo; can i.a. Visitor numbers and behavior (e.g. number of page views, duration of a website visit, bounce rates), visitor sources (i.e. which site the visitor comes from), visitor locations and technical data (browser and operating system versions) are analyzed. </span>
          <span>The analyzes run completely in the background. Website visitors are not informed that an analysis is taking place. </span>
          <span>The data is stored and analyzed on the basis of Art. 6 Para. 1 lit. f GDPR. The website operator has a legitimate interest in protecting its web offers from abusive automated spying and from SPAM. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Article 6 (1) (a) GDPR; the consent can be revoked at any time. </span>
          <span>For more information about Google Analytics, see the Google Privacy Policy and Google Terms of Use under the following links: <a href="https://policies.google.com/privacy?hl=de" target=" _blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a> and <a href="https://policies.google.com/terms?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/terms?hl=en</a>. </span>

          <h2 className={h2Classes}>7. newsletters</h2>

          <span>No newsletter is offered on this website.</span>

          <h2 className={h2Classes}>8. Plugins and Tools</h2>
          <h3 className={h3Classes}>Google Web Fonts</h3>
          <span>This site uses so-called web fonts provided by Google for the uniform display of fonts. When you call up a page, your browser loads the required web fonts into your browser cache in order to display text and fonts correctly.</span>
          <span>For this purpose, the browser you are using must connect to the Google servers. This gives Google knowledge that this website was accessed via your IP address. Google WebFonts are used on the basis of Article 6 (1) (f) GDPR. The website operator has a legitimate interest in the uniform presentation of the typeface on his website. If a corresponding consent has been requested (e.g. consent to the storage of cookies), the processing takes place exclusively on the basis of Article 6 (1) (a) GDPR; consent can be revoked at any time.</span> <span>If your browser does not support web fonts, a standard font will be used by your computer.</span>
          <span>More information about Google You can find web fonts at <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> and in Google's privacy policy: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies .google.com/privacy?hl=de</a>.</span>

          <h3 className={h3Classes}>Google reCAPTCHA</h3>
          <span>We use &ldquo;Google reCAPTCHA&rdquo; (hereinafter &ldquo;reCAPTCHA&rdquo;) on this website. The provider is Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4, Ireland. </span>
          <span>The purpose of reCAPTCHA is to check whether data entry on this website (e.g. in a contact form) is done by a human or by an automated program. To do this, reCAPTCHA analyzes the behavior of the website visitor based on various characteristics. This analysis starts automatically as soon as the website visitor enters the website. For the analysis, reCAPTCHA evaluates various information (e.g. IP address, how long the website visitor spends on the website or mouse movements made by the user). The data collected during the analysis is forwarded to Google. </span>
          <span>The reCAPTCHA analyzes run completely in the background. Website visitors are not informed that an analysis is taking place. </span>
          <span>The data is stored and analyzed on the basis of Art. 6 Para. 1 lit. f GDPR. The website operator has a legitimate interest in protecting its web offers from abusive automated spying and from SPAM. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Article 6 (1) (a) GDPR; the consent can be revoked at any time. </span>
          <span>For more information about Google reCAPTCHA, see the Google Privacy Policy and the Google Terms of Use under the following links: <a href="https://policies.google.com/privacy?hl=de" target=" _blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a> and <a href="https://policies.google.com/terms?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/terms?hl=en</a>. </span>

          <p className="mt-3">Source: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
        </section>
      </div>
    </main >
  )
}
